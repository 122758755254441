import withRedux, { ReduxWrapperAppProps } from 'next-redux-wrapper';
import { resetAppMetaData, resolveSeoUrl, setAppMetaData, setCurrentPageType } from 'src/modules/app/actions';
import { useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import HeadMetaData from '../modules/app/components/headMetaData';
import { addUrlFunction, loadRoutes } from '../routing/utils';
import { RoutesProvider } from '../routing/utils/routesContext';
import { ApplicationContext, ApplicationContextType } from 'src/common/applicationWrapper/applicationContext';
import lzwcompress from 'lzwcompress';
import { initStore } from '../storage/store';
import { RoutesEnum } from '../routing/types';
import { NextAppContext, NextComponentProps, NextPageComponent, State } from '../types';
import { DehydratedState } from 'react-query/types/hydration';
import { parseCookie } from '../utils/cookies';
import { setCookies } from '../modules/app/actions/shared';
import App, { AppContext } from 'next/app';
import { queryParamToString } from '../storage/query';
import { NextComponentType } from 'next';
import { ReactQueryProvider } from '../common/applicationWrapper/reactQueryProvider';
import { CookiesProvider } from 'react-cookie';
import GlobalListeners from '../modules/ui/globalListeners';
import ThemeProvider from '@ds/components/themeProvider';
import { Theme } from '@ds/design-tokens/src/type';
import { getTheme } from '../utils/theme';
import GlobalStyle from '../styles/global/globalStyle';
import { SeoAttributeType, SeoResourceType } from '../modules/app/models/enums';
import { ResolveSeoUrlActionFinish } from '../modules/app/actions/actionTypes';
import { HttpStatus } from '../types/enums/network';
import { getAttribute, translateSeoUrlType } from '../helpers/seoUrlHelder';
import { getServiceList } from '../modules/service/action';
import { getPageDetailById } from '../modules/page/action';
import { GtmBody } from '../lib/gtm';

interface InitialProps {
  theme?: Theme;
  routes?: RoutesEnum;
  applicationContext?: ApplicationContextType['value'];
  pageProps: NextComponentProps;
}

interface AppProps extends Omit<ReduxWrapperAppProps<State, any>, 'pageProps'>, InitialProps {}

const Application: NextComponentType<NextAppContext, InitialProps, AppProps> = ({
  Component: PropsComponent,
  store,
  pageProps,
  ...rest
}) => {
  const [theme] = useState(rest.theme || ({} as Theme));
  const [routes] = useState(addUrlFunction(rest.routes));
  const [applicationContext, setApplicationContext] = useState(rest.applicationContext);

  const applicationContextValue = useMemo(
    () => ({ value: applicationContext, setValue: setApplicationContext }),
    [applicationContext, setApplicationContext]
  );
  const Component = PropsComponent as unknown as NextPageComponent;
  const ComponentLayout = Component.settings.layout;
  const layoutProps = Component.settings.layoutProps || {};

  return (
    <ApplicationContext.Provider value={applicationContextValue}>
      <Provider store={store}>
        <ReactQueryProvider dehydratedState={pageProps.dehydratedState}>
          <ThemeProvider theme={theme}>
            <RoutesProvider value={routes}>
              <GlobalListeners />
              <GlobalStyle />
              <HeadMetaData />
              <GtmBody />
              <CookiesProvider>
                <ComponentLayout {...layoutProps}>
                  <Component {...pageProps} />
                </ComponentLayout>
              </CookiesProvider>
            </RoutesProvider>
          </ThemeProvider>
        </ReactQueryProvider>
      </Provider>
    </ApplicationContext.Provider>
  );
};

Application.getInitialProps = async (appContext) => {
  let initialProps: InitialProps = { pageProps: {} };
  const query = appContext.router.query;
  const Component = appContext.Component as unknown as NextPageComponent;
  const { ctx: pageContext } = appContext;
  const { dispatch } = pageContext.store;
  let queryClient: DehydratedState | undefined;

  const req = pageContext.req;
  const res = pageContext.res;

  const [app] = queryParamToString(query.app);

  if (req) {
    const configuration = req.configuration;
    const [theme, routes] = await Promise.all([getTheme(), loadRoutes()]);

    initialProps = {
      ...initialProps,
      theme,
      routes,
      applicationContext: {
        configuration,
        deviceInfo: { ...req.useragent, isApp: app === 'true' },
        versions: req.versions,
      },
    };

    const cookies = parseCookie(req.headers.cookie || '');

    dispatch(setCookies(cookies));
  }

  dispatch(resetAppMetaData());

  const { pageProps } = (await App.getInitialProps(appContext as AppContext)) as { pageProps: NextComponentProps };

  let { image, title, desc, robots } = Component.settings;

  if (pageProps) {
    title = pageProps.title || title;
    image = pageProps.image || image;
    desc = pageProps.desc || desc;
    robots = pageProps.robots || robots;

    dispatch(setAppMetaData(title, image, desc, robots));
  }

  dispatch(setCurrentPageType(Component.settings.pageType || null));

  let resourceId = null;

  if (Component.settings.seoResourceType == SeoResourceType.PAGE) {
    const { result } = (await dispatch(
      resolveSeoUrl({
        value: Component.settings.pageType as string,
        resourceType: Component.settings.seoResourceType,
      })
    )) as ResolveSeoUrlActionFinish;

    if (result == null) {
      res?.writeHead(HttpStatus.TemporaryRedirect, { Location: '/error/404' }).end();
    }

    if (result?.redirectCode != null) {
      res
        ?.writeHead(result?.redirectCode, {
          Location: `/${translateSeoUrlType(result.redirectType as SeoResourceType)}/${result.redirectPath}`,
        })
        .end();
    }

    dispatch(
      setAppMetaData(
        getAttribute(SeoAttributeType.TITLE, result?.attributes)?.value,
        getAttribute(SeoAttributeType.IMAGE, result?.attributes)?.value,
        getAttribute(SeoAttributeType.DESCRIPTION, result?.attributes)?.value,
        getAttribute(SeoAttributeType.ROBOTS, result?.attributes)?.value
      )
    );

    await dispatch(getServiceList({}));
    await dispatch(getPageDetailById(result?.resourceId as string));

    resourceId = result?.resourceId as string;
  }

  return {
    ...initialProps,
    pageProps: {
      ...pageProps,
      resourceId,
      dehydratedState: {
        mutations: [...(queryClient?.mutations || []), ...(pageProps.dehydratedState?.mutations || [])],
        queries: [...(queryClient?.queries || []), ...(pageProps.dehydratedState?.queries || [])],
      },
    },
  };
};

export default withRedux(initStore, {
  serializeState: lzwcompress.pack,
  deserializeState: lzwcompress.unpack,
})(Application);
