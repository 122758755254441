import Head from 'next/head';
import React from 'react';
import {useSelector} from 'react-redux';
import {useConfiguration} from '../../../configuration/useConfiguration';

import {selectAppMetaData} from '../reducer/selectors';
import {useHeadMetaData} from '../../../utils/hooks/useHeadMetaData';
import {GtmScript} from '../../../lib/gtm';

interface ComponentProps {
  hasTitleAppend?: boolean;
  robots?: boolean;
  title?: string;
}

const HeadMetaData: React.FC<ComponentProps> = () => {
  const { brandName, author, cdnUrl } = useConfiguration();

  const metaData = useSelector(selectAppMetaData);

  const { favicon, icon16, icon32, appleIcon, safari, manifest } = useHeadMetaData();

  const title = metaData.title || 'Vývoj aplikací, integrace systémů, teamlease';
  const description =
    metaData.description ||
    'Pokrýváme celé spektrum IT služeb a technologií a zapůjčíme vám vývojáře, architekty, projektové manažery i testery na úrovni junior, medior nebo senior.';

  const pageTitle = title + ' | Etyka Digital';

  return (
    <>
      <Head>
        <title>{pageTitle}</title>

        <meta content={author} name="author" />
        <meta content={brandName} property="og:site_name" />
        <meta name="facebook-domain-verification" content="2rhpb70n2ru30ytk86nna7wq2jhypc" />

        <link rel="shortcut icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={icon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={icon16} />
        <link rel="manifest" href={manifest} />
        <link rel="mask-icon" href={safari} color="#f3bc51" />
        <meta content="width=device-width,initial-scale=1.0,maximum-scale=5" name="viewport" />
        <meta content={title} property="og:title" />
        {description && (
          <>
            <meta content={description} name="description" />
            <meta content={description} property="og:description" />
          </>
        )}

        {metaData.image && (
          <>
            <meta content={cdnUrl + metaData.image} property="og:image" />
            <meta content="1200" property="og:image:width" />
            <meta content="630" property="og:image:height" />
          </>
        )}
        {!metaData.robots && <meta content="all, follow" name="robots" />}
        {metaData.robots && <meta content={`${metaData.robots}`} name="robots" />}
      </Head>
      <GtmScript gtmId={'GTM-PX9THSCK'} />
    </>
  );
};

export default HeadMetaData;
