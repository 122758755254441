import getNextConfig from 'next/config';
import {PublicRuntimeConfig} from '../types/next';

/**
 * Returns part of configurations. Please use in components useConfiguration() instead
 * @returns next publicRuntimeConfig
 */
export function getConfig(): PublicRuntimeConfig {
  return getNextConfig().publicRuntimeConfig;
}
