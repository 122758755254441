import {Page} from '../../common/models';
import {BlogCategoryRow, BlogDetail, BlogRow} from '../models';
import {BlogAction} from '../action/actionTypes';

export interface State {
  blog: {
    list: Page<BlogRow> | undefined;
    categories: Page<BlogCategoryRow> | undefined;
    detail: {
      data: BlogDetail | undefined;
    };
  };
}

export const initialState: State = {
  blog: {
    list: undefined,
    categories: undefined,
    detail: {
      data: undefined,
      // seo: {
      //     url: undefined,
      //     attributes: undefined,
      // },
    },
  },
};

export default function reducer(state: State = initialState, action: BlogAction): State {
  switch (action.type) {
    case 'GET_BLOG_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        blog: {
          ...state.blog,
          list: action.result,
        },
      };
    }

    case 'APPEND_BLOG_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }
      if (state.blog.list?.content && action.result?.content) {
        action.result.content = state.blog.list.content.concat(action.result.content);
      }

      return {
        ...state,
        blog: {
          ...state.blog,
          list: action.result,
        },
      };
    }
    case 'GET_BLOG_CATEGORY_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        blog: {
          ...state.blog,
          categories: action.result,
        },
      };
    }
    case 'GET_BLOG_DETAIL_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        blog: {
          ...state.blog,
          detail: {
            ...state.blog.detail,
            data: action.result,
          },
        },
      };
    }
    default:
      return state;
  }
}
