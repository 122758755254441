import {css} from 'styled-components';

const buttonComponent = css(
  ({ theme }) => css`
    .simpleLinkBtn {
      background: transparent;
      padding: 0;
      font-weight: normal;
      border: 0;
      border-radius: 0;
      height: auto;
      line-height: 1;
      color: inherit;

      &:hover {
        color: ${theme.colorTextInverted};
      }
    }

    .button-default {
      padding: 0 2em;
      border-radius: 4px;
      text-align: center;
      position: relative;
      transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
      line-height: 36px;
      border: none;

      a {
        text-decoration: none !important;
        color: inherit;
        line-height: inherit;
      }
    }

    .button-secondary {
      background-color: #8d8d8d;
      color: white;
    }

    .button-danger {
      background-color: #db3545;
      color: #ffffff;
    }

    .button-success {
      color: white;
      background: linear-gradient(0deg, #79d231, #79d231), linear-gradient(63.45deg, #a8d91c -1.43%, #2dc753 94.42%);
    }
  `
);

export default buttonComponent;
