import {SeoAttributeType, SeoResourceType} from '../modules/app/models/enums';
import {SeoAttribute} from '../modules/app/models';

export const translateSeoUrlType = (type: SeoResourceType) => {
  switch (type) {
    case SeoResourceType.SERVICE:
      return 'sluzby';
    case SeoResourceType.ARTICLE:
      return 'clanek';
    case SeoResourceType.PROJECT:
      return 'pripadova-studie';
    default:
      return null;
  }
};

export const getAttribute = (type: SeoAttributeType, data: SeoAttribute[] | undefined): SeoAttribute | null => {
  const attribute: SeoAttribute | undefined = data?.find((r) => r.type == type);

  return attribute ?? null;
};
