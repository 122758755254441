import {css} from 'styled-components';

const helpersFlex = css`
  .flexRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .flexStartItemsCenter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flexEndItemsCenter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .flexJustifyBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default helpersFlex;
