import urlLib from 'url';
import {deviceType} from 'detect-it';
import {ServerResponse} from 'http';
import {NextPageRequest} from '../../../types';
import {ApiFunction, ApiMethod, Options} from '../types';
import {getPath} from '../utils';
import {getAuthorizationHeaders} from '../../../../server/utils/headers';
import {GenericResponse} from '../../../modules/common/models';

export interface IApi {
  get: ApiFunction;
  post: ApiFunction;
  put: ApiFunction;
  patch: ApiFunction;
  delete: ApiFunction;
}

const SUPPORTED_METHODS = [ApiMethod.get, ApiMethod.post, ApiMethod.put, ApiMethod.patch, ApiMethod.delete];

export default class ApiClient implements IApi {
  public req: Nullable<NextPageRequest>;

  public res?: ServerResponse;

  public get!: ApiFunction;
  public post!: ApiFunction;
  public put!: ApiFunction;
  public patch!: ApiFunction;
  public delete!: ApiFunction;

  constructor(req?: NextPageRequest, res?: any) {
    this.req = req;
    this.res = res;
    SUPPORTED_METHODS.forEach((m) => {
      this[m] = this.createMethod(m);
    });
  }

  protected async call(url: string, method: ApiMethod, body?: string | FormData, headers?: HeadersInit) {
    const response = await fetch(url, {
      headers,
      body,
      method: method.toUpperCase(),
      credentials: 'include',
      mode: 'cors',
    });

    const data: GenericResponse<any> = await response.json();

    return data;
  }

  private createMethod(method: ApiMethod): ApiFunction {
    return (endPointName, options = {}) => {
      const { data, urlParts, urlParams, formData } = options;
      const url = getPath(endPointName, urlParts, urlParams);

      // eslint-disable-next-line
      return new Promise(async (resolve, reject) => {
        try {
          const result = await this.call(
            url,
            method,
            data ? JSON.stringify(data) : formData,
            this.getHeaders(this.req, options)
          );
          resolve(result as any);
        } catch (err: any) {
          const finalError = err?.response?.body || err;

          if (finalError.data?.data) {
            finalError.data = finalError.data.data;
          }

          console.error('>> ERROR:', method.toUpperCase(), url, err); // eslint-disable-line

          reject(finalError);
        }
      });
    };
  }

  protected getHeaders = (req: Nullable<NextPageRequest>, options: Options): Headers => {
    const headers = new Headers({
      cookie: req?.headers?.cookie || '',
      'X-Origin': deviceType === 'touchOnly' ? 'WEB_MOBILE' : 'WEB',
      'User-Agent': req?.headers['user-agent'] ?? 'react',
    });

    if (!options.formData) {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
    }

    const { accessToken = null } = this.req?.url ? urlLib.parse(this.req.url, true).query : {};

    if (accessToken) {
      const authHeaders = getAuthorizationHeaders(accessToken.toString());
      Object.entries(authHeaders).forEach(([key, val]) => {
        headers.set(key, val);
      });
    }

    if (options.timeout) {
      headers.set('timeout', `${options.timeout}`);
    }

    if (options.headers) {
      Object.entries(options.headers).map(([name, value]) => headers.set(name, value));
    }

    // console.log(headers);

    return headers;
  };
}
