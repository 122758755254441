import buttonComponent from './components/buttonComponent';
import iconComponent from './components/iconComponent';
import inputComponent from './components/inputComponent';
import button from './elements/button';
import form from './elements/form';
import headings from './elements/headings';
import images from './elements/images';
import input from './elements/input';
import label from './elements/label';
import links from './elements/links';
import lists from './elements/lists';
import page from './elements/page';
import select from './elements/select';
import text from './elements/text';
import fonts from './generic/fonts';
import normalize from './generic/normalize';
import helpersButton from './helpers/button';
import helpersFlex from './helpers/flex';
import imagePlaceholder from './helpers/imagePlaceholder';
import helpersText from './helpers/text';
import block from './utilities/block';
import colors from './utilities/colors';
import cursor from './utilities/cursor';
import display from './utilities/display';
import flex from './utilities/flex';
import positions from './utilities/positions';
import spacing from './utilities/spacing';
import typography from './utilities/typography';
import { createGlobalStyle } from 'styled-components';
import { GlobalStyleProps } from './types';
import mobile from './utilities/mobile';
import container from './utilities/container';
import { sizes } from '../mediaQueries';

const Base = createGlobalStyle<GlobalStyleProps>`
  /**
   * 1. Generic
   * ==========
   * Ground-zero styles.
  **/

  ${normalize}
  ${fonts}
  ${container}
    /**
     * 2. Elements
     * ===========
     * Unclassed HTML elements (type selectors).
    **/
      /**  ${button}  **/
  ${form}
  ${headings}
  ${images}
  ${input}
  ${label}
  ${links}
  ${lists}
  ${page}
  ${select}
  ${text}
  ${helpersButton}
  ${helpersFlex}
  ${imagePlaceholder}
  ${helpersText}
    /**
     * 5. Components
     * =============
     * Actual UI components.
    **/
  ${buttonComponent}
  ${iconComponent}
  ${inputComponent}
    /**
     * 6. Utilities
     * ============
     * High-specificity overrides. The only place where \`!important\` is allowed.
     * For generate all utilities classes please run \`yarn generate:css\`.
    **/
  ${block}
  ${colors}
  ${cursor}
  ${display}
  ${flex}
  ${positions}
  ${spacing}
  ${typography}
  ${mobile}
  .fw-bolder {
    font-weight: bolder;
  }

  .alt-ss01 {
    -webkit-font-feature-settings: "ss01";
    -moz-font-feature-settings: "ss01";
    -o-font-feature-settings: "ss01";
    font-feature-settings: 'ss01';
  }

  .d-block {
    display: block !important;
  }

  .d-none {
    display: none !important;
  }

  .btn-arrow {
    font: normal normal 950 16px/60px 'Area Normal Black';
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    height: 20px;
    user-select: none;

    &:hover .arrow {
      width: 31px;
    }

    .arrow {
      position: relative;
      width: 10px;
      height: 0;
      border-top: 1px solid #000000;
      opacity: 1;
      margin-left: 1.5rem;
      transition: width .15s ease-in;
      top: -2px;

      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        position: absolute;
        right: -7px;
        top: -4px;
        background: #000000 0 0 no-repeat padding-box;
        opacity: 1;
        aspect-ratio: 1;
        clip-path: polygon(100% 0, 100% 100%, 0 50%);
        transform: rotate(-180deg);

      }
    }
  }

  .btn-arrow-vertical {
    font: normal normal 950 16px/2rem 'Area Normal Black';
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    user-select: none;

    &:hover .arrow {
      height: 15px;
      margin-top: 0.5rem;

      &:after {
        margin-top: 0.5rem;
      }
    }

    .arrow {
      position: relative;
      height: 10px;
      //height: 0;
      border-right: 1px solid #000000;
      opacity: 1;
      margin-left: 1.5rem;
      transition: height .15s ease-in, margin .15s ease-in;

      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        position: absolute;
        left: -3px;
        top: 8px;
        background: #000000 0 0 no-repeat padding-box;
        opacity: 1;
        aspect-ratio: 1;
        clip-path: polygon(100% 0, 100% 100%, 0 50%);
        transform: rotate(-90deg);
        transition: margin .15s ease-in;

      }
    }

    .r-90 {
      transform: rotate(90deg);
    }
  }

  .lone-arrow {
    position: relative;
    width: 10px;
    height: 0;
    border-top: 1px solid #000000;
    opacity: 1;
    transition: width .15s ease-in;

    &:after {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      position: absolute;
      right: -7px;
      top: -4px;
      background: #000000 0 0 no-repeat padding-box;
      opacity: 1;
      aspect-ratio: 1;
      clip-path: polygon(100% 0, 100% 100%, 0 50%);
      transform: rotate(-180deg);
    }
  }

  .block {
    &-headline {
      display: flex;
      align-items: center;
      //height: 100px;
      font: normal normal 950 20px 'Area Extended Extrablack';
      margin: 0;
      gap: 2rem;

      @media (max-width: ${sizes.mDevice}) {
        flex-wrap: wrap;
        gap: 0;
        padding-bottom: 0;
        font: normal normal 950 18px/30px 'Area Extended Extrablack';

        div:first-child {
          width: 100%;
        }
      }

      .btn-arrow {
        font: normal normal 950 16px 'Area Normal Black';
        padding: 0;
        height: initial;
        margin-top: 2px;
        @media (max-width: ${sizes.mDevice}) {
          padding-top: 40px;

        }
      }
    }

    &-cta {
      display: block;
      text-align: center;
      margin-top: 120px;
    }
  }

  .btn-cta-primary, .btn-cta-secondary {
    transition: background-color 0.1s ease-in-out;
    border: 1px solid #f6e600;
    border-radius: 4px;
    padding: 1.5rem 3rem;
    font: normal 950 15px 'Area Normal Black';
    width: fit-content;
    cursor: pointer;
    user-select: none;
    background-color: #f6e600;

    :hover {
      background-color: #e8d900;
      border-color: #e8d900;
    }
  }

  .btn-cta-secondary{
    border: 1px solid black;
    background-color: transparent;

    :hover {
      background-color: #f6e600;
      border-color: #f6e600;
    }
  }

`;

export default Base;
