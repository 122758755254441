import {isServer} from 'src/utils';
import {ecommerceGA4Event} from './GA4';
import {GA4Event, GTMEvent} from './types';
import {GTM_DEFAULT_DATALAYER_NAME} from './constants';

const updateDataLayer = (
  event: GA4Event | GTMEvent,
  dataToEmit?: Record<string, any>,
  dataLayerName: string = GTM_DEFAULT_DATALAYER_NAME
) => {
  if (isServer()) {
    return;
  }

  window[dataLayerName] = window[dataLayerName] || [];
  const dataLayer = window[dataLayerName];

  if (dataLayer && dataToEmit) {
    // GA4 ecommerce events
    // @ts-ignore
    if (Object.values(GA4Event).includes(event)) {
      // @ts-ignore
      const data = ecommerceGA4Event(event, dataToEmit);
      return dataLayer.push({ data, event });
    }

    dataLayer.push({ ...(dataToEmit || {}), event });
  }
};

export const GoogleTagManager = {
  updateDataLayer,
};
