import {combineReducers} from 'redux';
import app, {initialState as AppInitialState} from '../modules/app/reducer';
import projects, {initialState as ProjectsInitialState} from '../modules/project/reducer';
import blog, {initialState as BlogInitialState} from '../modules/blog/reducer';
import job, {initialState as JobInitialState} from '../modules/jobs/reducer';
import homepage, {initialState as HomepageInitialState} from '../modules/homepage/reducer';
import service, {initialState as ServiceInitialState} from '../modules/service/reducer';
import pages, {initialState as PagesInitialState} from '../modules/page/reducer';

export type ApplicationState = typeof initialState;

export const initialState = {
  app: AppInitialState,
  projects: ProjectsInitialState,
  blog: BlogInitialState,
  job: JobInitialState,
  homepage: HomepageInitialState,
  service: ServiceInitialState,
  pages: PagesInitialState,
};

export default combineReducers<ApplicationState>({
  // INFO: this cast is here because notifications reducer from 'react-notification-system-redux'
  // does not support Reducer signature from 'redux' (please see) - initial state can be undefined and
  app,
  projects,
  blog,
  homepage,
  job,
  service,
  pages,
});
