import {Page} from '../../common/models';
import {HomepageAction} from '../action/actionTypes';
import {ProjectsRow} from '../../project/models';

export interface State {
  mainProjects: Page<ProjectsRow> | undefined;
  projects: Page<ProjectsRow> | undefined;
}

export const initialState: State = {
  mainProjects: undefined,
  projects: undefined,
};

export default function reducer(state: State = initialState, action: HomepageAction): State {
  switch (action.type) {
    case 'GET_MAIN_PROJECT_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        mainProjects: action.result,
      };
    }
    case 'GET_HOMEPAGE_PROJECT_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        projects: action.result,
      };
    }
    default:
      return state;
  }
}
