import {Action, ActionFunction} from 'src/types';
import createReduxAction from '../../common/actionCreator';
import {GoogleTagManager, GTMEvent} from '../../../lib/gtm';

export function setAppMetaData(
  title?: Nullable<string>,
  image?: Nullable<string>,
  description?: Nullable<string>,
  robots?: string | null
): Action {
  GoogleTagManager.updateDataLayer(GTMEvent.APP_TITLE_CHANGE_EVENT, { title });

  return {
    type: 'SET_APP_META_DATA',
    metaData: {
      title,
      image,
      description,
      robots,
    },
  };
}

export function resetAppMetaData(): Action {
  return {
    type: 'RESET_APP_META_DATA',
  };
}

export function setCurrentPageType(pageType: Nullable<string>): Action {
  return {
    type: 'SET_CURRENT_PAGE_TYPE',
    pageType,
  };
}

export function resolveSeoUrl(payload: { resourceType: string; value: string }): ActionFunction<Action> {
  return createReduxAction({
    type: 'RESOLVE_SEO_URL_ACTION',
    promise: ({ api }) =>
      api.post('RESOLVE_SEO_URL', {
        data: payload,
      }),
  });
}
