export const fonts = {
  fontFamily:
    '-apple-system, "Area Normal Black", "Area Normal Extrablack", "Area Extended Extrablack", "Area Normal Medium", "Liberation Sans", Roboto, sans-serif',
  fontFamilyInterVar:
    '-apple-system, "BlinkMacSystemFont", "San Francisco", "Segoe UI", "Helvetica Neue", "Liberation Sans", Roboto, sans-serif',
};

export const spacing = 4;

export const hacks = {
  edgeHack: '(-ms-ime-align:auto)',

  // iOS 9-10 - iPhone5
  iOSHack: 'screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)',
};
