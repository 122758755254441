import {Page} from '../../common/models';
import {ProjectsCategoryRow, ProjectsDetail, ProjectsRow} from '../models';
import {ProjectsAction} from '../action/actionTypes';

export interface State {
  project: {
    list: Page<ProjectsRow> | undefined;
    categories: Page<ProjectsCategoryRow> | undefined;
    detail: {
      data: ProjectsDetail | undefined;
    };
  };
}

export const initialState: State = {
  project: {
    list: undefined,
    categories: undefined,
    detail: {
      data: undefined,
      // seo: {
      //     url: undefined,
      //     attributes: undefined,
      // },
    },
  },
};

export default function reducer(state: State = initialState, action: ProjectsAction): State {
  switch (action.type) {
    case 'GET_PROJECT_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        project: {
          ...state.project,
          list: action.result,
        },
      };
    }
    case 'APPEND_PROJECT_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      if (state.project.list?.content && action.result?.content) {
        action.result.content = state.project.list.content.concat(action.result.content);
      }

      return {
        ...state,
        project: {
          ...state.project,
          list: action.result,
        },
      };
    }
    case 'GET_PROJECT_CATEGORY_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        project: {
          ...state.project,
          categories: action.result,
        },
      };
    }
    case 'GET_PROJECT_DETAIL_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        project: {
          ...state.project,
          detail: {
            ...state.project.detail,
            data: action.result,
          },
        },
      };
    }
    default:
      return state;
  }
}
