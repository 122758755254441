import {Action, ActionFunction, Cookie} from '../../../types';
import {CookieOptions} from 'express';
import {selectCookies} from '../reducer/selectors';
import ReactCookies from 'react-cookies';
import {Coordinates} from '../models';

/**
 * sets cookie. in case when value is undefined or null the key is removed
 * @param key cookie key
 * @param value cookie value
 * @param options cookie options
 */
export function setCookie(key: Cookie, value: any, options?: CookieOptions): ActionFunction<Action> {
  return async (dispatch, getState) => {
    const stateCookies = selectCookies(getState());
    let cookies = { ...stateCookies };

    if (value === undefined || value === null) {
      ReactCookies.remove(key);
      cookies = Object.entries(cookies).reduce((acc, [cookieKey, cookieVal]) => {
        return cookieKey === key ? acc : { ...acc, [cookieKey]: cookieVal };
      }, {}) as typeof stateCookies;
    } else {
      cookies = { ...cookies, [key]: value };
      const val = typeof value === 'string' ? value : JSON.stringify(value);
      const yearInMilis = 1 * 365 * 24 * 60 * 60;
      ReactCookies.save(key, val, options || { maxAge: yearInMilis, path: '/' });
    }

    return dispatch(setCookies(cookies));
  };
}

export function setCookies(cookies: { [key in Cookie]: any }): Action {
  return {
    type: 'SET_COOKIES',
    cookies,
  };
}

export function setCoordinates(coordinates: Coordinates): Action {
  return {
    type: 'SET_COORDINATES',
    coordinates,
  };
}
