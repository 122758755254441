import {AppMetaData} from './index';

export function emptyAppMetaData(): AppMetaData {
  return {
    title: null,
    image: null,
    description: null,
    robots: null,
  };
}
