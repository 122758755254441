import {Page} from '../../common/models';
import {ServicesDetail, ServicesRow} from '../models';
import {ServicesAction} from '../action/actionTypes';

export interface State {
  project: {
    list: Page<ServicesRow> | undefined;
    detail: {
      data: ServicesDetail | undefined;
    };
  };
}

export const initialState: State = {
  project: {
    list: undefined,
    detail: {
      data: undefined,
      // seo: {
      //     url: undefined,
      //     attributes: undefined,
      // },
    },
  },
};

export default function reducer(state: State = initialState, action: ServicesAction): State {
  switch (action.type) {
    case 'GET_SERVICE_LIST_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        project: {
          ...state.project,
          list: action.result,
        },
      };
    }
    case 'GET_SERVICE_DETAIL_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        project: {
          ...state.project,
          detail: {
            ...state.project.detail,
            data: action.result,
          },
        },
      };
    }
    default:
      return state;
  }
}
