import {fonts} from 'src/styles/variables';
import {css} from 'styled-components';

const page = css(
  ({ theme }) => css`
    html {
      scroll-behavior: smooth;
      box-sizing: border-box;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      font-family: ${fonts.fontFamily};
      font-size: 62.5%;
      height: 100%;
      width: 100%;

      @supports (font-variation-settings: normal) {
        font-family: ${fonts.fontFamilyInterVar};
      }
    }

    body {
      overflow-x: hidden;
      overflow-y: auto;
      color: ${theme.colorTextPrimary};
      background: ${theme.backgroundColorPrimary};
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
      width: 100%;
      height: 100%;
      line-height: 1;
      left: 0;
      bottom: 0;
      right: 0;
      margin-top: 0;
    }

    :disabled,
    [disabled='true'],
    .disabled,
    .notValid {
      &:active,
      &:hover {
        transition: none !important;
        transform: none !important;
      }
    }
  `
);

export default page;
