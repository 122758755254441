import {AppMetaData, Cookie} from '../../../types';
import {AppAction} from '../actions/actionTypes';
import {emptyAppMetaData} from '../../../types/empty';
import {Coordinates} from '../models';

export interface AppState {
  currentPageType: Nullable<string>;
  metaData: AppMetaData;
  cookies: { [cookie in Cookie]: any };
  coordinates: Coordinates | null;
}

export const initialState: AppState = {
  currentPageType: null,
  metaData: emptyAppMetaData(),
  cookies: {} as AppState['cookies'],
  coordinates: null,
};

export default function reducer(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case 'RESET_APP_META_DATA': {
      return {
        ...state,
        metaData: emptyAppMetaData(),
      };
    }
    case 'SET_APP_META_DATA': {
      const { metaData } = action;

      return {
        ...state,
        metaData: Object.keys(metaData).reduce<AppMetaData>((acc, key) => {
          const k = key as keyof AppMetaData;
          if (metaData[k] != undefined) {
            acc = { ...acc, [k]: metaData[k] };
          }
          return acc;
        }, state.metaData),
      };
    }

    case 'SET_CURRENT_PAGE_TYPE': {
      if (action.pageType === state.currentPageType) {
        return state;
      }

      return {
        ...state,
        currentPageType: action.pageType,
      };
    }
    case 'SET_COOKIES': {
      return {
        ...state,
        cookies: action.cookies,
      };
    }
    case 'SET_COORDINATES': {
      return {
        ...state,
        coordinates: action.coordinates,
      };
    }

    default:
      return state;
  }
}
