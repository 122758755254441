import {useContext} from 'react';
import RoutesContext from './routesContext';

const useRoutes = () => {
  const routes = useContext(RoutesContext);

  if (routes === undefined) {
    throw new Error('useRoutes must be used within a RoutesProvider');
  }

  return routes;
};

export default useRoutes;
