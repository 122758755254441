import {PagesDetail} from '../models';
import {PagesAction} from '../action/actionTypes';

export interface State {
  page: {
    data: PagesDetail | undefined;
  };
}

export const initialState: State = {
  page: {
    data: undefined,
  },
};

export default function reducer(state: State = initialState, action: PagesAction): State {
  switch (action.type) {
    case 'GET_PAGE_DETAIL_ACTION_FINISH': {
      if (!action.result) {
        return { ...state };
      }

      return {
        ...state,
        page: {
          ...state.page,
          data: action.result,
        },
      };
    }
    default:
      return state;
  }
}
