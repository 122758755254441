import {css} from 'styled-components';

const text = css`
  p {
    margin: 0;
    line-height: 1.4;
  }

  .text-start {
    text-align: start;
  }

  .text-center {
    text-align: center;
  }
`;

export default text;
