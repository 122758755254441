import {ActionFunction} from '../../../types';
import {Action} from 'redux';
import createReduxAction from '../../common/actionCreator';

export function getPageDetailById(serviceId: string): ActionFunction<Action> {
  return createReduxAction({
    type: 'GET_PAGE_DETAIL_ACTION',
    promise: ({ api }) =>
      api.get('PAGE_BY_ID', {
        urlParts: [serviceId],
      }),
  });
}
