import {Cookie} from '../types';

export const parseCookie = (cookie: string) => {
  return cookie.split(';').reduce((acc, item) => {
    const [key, value] = item.split('=');
    if (!key || !value) {
      return acc;
    }
    let val = value.trim();
    try {
      val = JSON.parse(val);
    } catch (e) {
      // do nothing
    }
    return { ...acc, [key.trim()]: val };
  }, {} as { [k in Cookie]: any });
};

export const stringifyCookies = (cookies: { [key in Cookie]: any }): string => {
  return Object.entries(cookies)
    .map(([key, val]) => `${key}=${JSON.stringify(val)}`)
    .join('; ');
};
