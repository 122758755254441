import {css} from 'styled-components';

const fonts = css`
  @font-face {
    font-family: 'Area Normal Black';
    src: url('/fonts/AreaNormal-Black.woff2') format('woff2'), url('/fonts/AreaNormal-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Area Normal Medium';
    src: url('/fonts/AreaNormal-Medium.woff2') format('woff2'), url('/fonts/AreaNormal-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Area Extended Extrablack';
    src: url('/fonts/AreaExtended-Extrablack.woff2') format('woff2'),
      url('/fonts/AreaExtended-Extrablack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Area Normal Extrablack';
    src: url('/fonts/AreaNormal-Extrablack.woff2') format('woff2'),
      url('/fonts/AreaNormal-Extrablack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;

export default fonts;
