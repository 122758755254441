import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectCurrentPageType} from '../app/reducer/selectors';
import {useApplicationContext} from '../../common/applicationWrapper/useApplicationContext';
import {Cookie} from '../../types';
import useRouter from '../../routing/utils/useRouter';
import {useRouting} from '../../routing/utils/useRouting';
import {GA4Event, GoogleTagManager} from '../../lib/gtm';

interface GlobalListenersProps {}

const GlobalListeners: React.FC<GlobalListenersProps> = () => {
  const { router } = useRouting();
  const dispatch = useDispatch();

  const {
    value: {
      configuration: { releaseId },
    },
  } = useApplicationContext();

  usePageTypeChange();

  useEffect(() => {
    // HACK for next-translate to add v param into query while loading namespaces
    window.releaseId = releaseId;
    history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    if (document.cookie.match('(^|;)\\s*' + Cookie.JWT_COOKIE + '\\s*=\\s*([^;]+)')) {
      return;
    }

    window.location.reload();
  }, []);

  return null;
};

const usePageTypeChange = () => {
  const pageType = useSelector(selectCurrentPageType);
  const router = useRouter();

  useEffect(() => {
    GoogleTagManager.updateDataLayer(GA4Event.APP_PAGE_TYPE_CHANGE_EVENT, { pageType });
    // ReactGA.send(router.asPath + window.location.search);
  }, [pageType]);
};
export default GlobalListeners;
