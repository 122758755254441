import {css} from 'styled-components';

const iconComponent = css`
  .icon-svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export default iconComponent;
