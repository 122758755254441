import {css} from 'styled-components';

const label = css`
  label {
    display: block;
    line-height: 1.85;
    font-size: 13px;
    text-align: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

export default label;
