import {Dispatch as ReduxDispatch, Middleware} from 'redux';
import {Action, State} from 'src/types';
import {isServer} from 'src/utils';

// eslint-disable-next-line
const globalEventsMiddleware: Middleware<{}, State, ReduxDispatch> =
  (
    params // eslint-disable-line
  ) =>
  (next) =>
  async (
    action: Function | Action // eslint-disable-line
  ): Promise<any> => {
    if (isServer() || typeof action === 'function') {
      return (next as (param: any) => Promise<Action>)(action);
    }
    return next(action);
  };

export default globalEventsMiddleware;
