export enum SeoResourceType {
  SERVICE = 'SERVICE',
  PAGE = 'PAGE',
  ARTICLE = 'ARTICLE',
  ARTICLE_CATEGORY = 'ARTICLE_CATEGORY',
  PROJECT = 'PROJECT',
  PROJECT_CATEGORY = 'PROJECT_CATEGORY',
  JOB = 'JOB',
  JOB_CATEGORY = 'JOB_CATEGORY',
  BLOG = 'BLOG',
  CONTACT = 'CONTACT',
}

export enum SeoAttributeType {
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  IMAGE = 'IMAGE',
  ROBOTS = 'ROBOTS',
}

export enum PageBlockContentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  PROJECT_CLIENT_BLOCK_NAME = 'PROJECT_CLIENT_BLOCK_NAME',
  PROJECT_CLIENT_BLOCK_POSITION = 'PROJECT_CLIENT_BLOCK_POSITION',
  PROJECT_CLIENT_BLOCK_DESCRIPTION = 'PROJECT_CLIENT_BLOCK_DESCRIPTION',
  PROJECT_CLIENT_BLOCK_IMAGE = 'PROJECT_CLIENT_BLOCK_IMAGE',
  TEXT_CIRCLE_IMAGE_LEFT = 'TEXT_CIRCLE_IMAGE_LEFT',
  TEXT_CIRCLE_IMAGE_RIGHT = 'TEXT_CIRCLE_IMAGE_RIGHT',
  USP_BLOCK_ROW_TITLE = 'USP_BLOCK_ROW_TITLE',
  USP_BLOCK_ROW_DESCRIPTION = 'USP_BLOCK_ROW_DESCRIPTION',
  MAIN_USP_BLOCK_TITLE = 'MAIN_USP_BLOCK_TITLE',
  MAIN_USP_BLOCK_DESCRIPTION = 'MAIN_USP_BLOCK_DESCRIPTION',
  STEPS_BLOCK_ROW_TITLE = 'STEPS_BLOCK_ROW_TITLE',
  STEPS_BLOCK_ROW_DESCRIPTION = 'STEPS_BLOCK_ROW_DESCRIPTION',
  RELATED_SERVICE_BLOCK_SERVICE_ID = 'RELATED_SERVICE_BLOCK_SERVICE_ID',
  RELATED_SERVICE_BLOCK_SERVICE_TITLE = 'RELATED_SERVICE_BLOCK_SERVICE_TITLE',
  RELATED_SERVICE_BLOCK_SERVICE_URL = 'RELATED_SERVICE_BLOCK_SERVICE_URL',
  MAIN_PROMO_BLOCK_LEFT_SIDE = 'MAIN_PROMO_BLOCK_LEFT_SIDE',
  MAIN_PROMO_BLOCK_RIGHT_SIDE = 'MAIN_PROMO_BLOCK_RIGHT_SIDE',
  PEOPLE_BLOCK_NAME = 'PEOPLE_BLOCK_NAME',
  PEOPLE_BLOCK_POSITION = 'PEOPLE_BLOCK_POSITION',
  PEOPLE_BLOCK_DESCRIPTION = 'PEOPLE_BLOCK_DESCRIPTION',
  PEOPLE_BLOCK_PHONE = 'PEOPLE_BLOCK_PHONE',
  PEOPLE_BLOCK_EMAIL = 'PEOPLE_BLOCK_EMAIL',
  PEOPLE_BLOCK_IMAGE = 'PEOPLE_BLOCK_IMAGE',
  TEXT_CONTAINER_IMAGE_BLOCK_IMAGE = 'TEXT_CONTAINER_IMAGE_BLOCK_IMAGE',
  PROJECT_SIDE_CUSTOMER = 'PROJECT_SIDE_CUSTOMER',
  PROJECT_SIDE_PROJECT_TERM = 'PROJECT_SIDE_PROJECT_TERM',
  PROJECT_SIDE_TECHNOLOGY = 'PROJECT_SIDE_TECHNOLOGY',
}
