import { EcommerceGTMGA4Result, GA4Event, GTMGA4 } from './types';

export const ecommerceGA4Event = <T extends GA4Event>(
  type: GA4Event | Record<string, unknown>,
  data: GTMGA4<T>,
  defaultCurrency: string
): EcommerceGTMGA4Result<T> => {
  switch (type) {
    case GA4Event.CONTACT_FORM_SENT:
      return {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
        message: data.message,
      };
  }
  return {} as EcommerceGTMGA4Result<T>;
};
