import {ServiceFinderPayload} from '../models';
import {ActionFunction} from '../../../types';
import {Action} from 'redux';
import createReduxAction from '../../common/actionCreator';

export function getServiceList(
  data: ServiceFinderPayload,
  page?: string | null,
  size?: string | null,
  sort?: string | null
): ActionFunction<Action> {
  return createReduxAction({
    type: 'GET_SERVICE_LIST_ACTION',
    promise: ({ api }) =>
      api.post('FILTER_SERVICE', {
        urlParams: { page, size, sort },
        data,
      }),
  });
}

export function getServiceDetailById(serviceId: string): ActionFunction<Action> {
  return createReduxAction({
    type: 'GET_SERVICE_DETAIL_ACTION',
    promise: ({ api }) =>
      api.get('SERVICE_BY_ID', {
        urlParts: [serviceId],
      }),
  });
}
