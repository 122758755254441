import {getConfig} from 'src/utils/next';

export type ApiEndpointName = keyof typeof API_CONFIG;

const {
  websiteApiService: { path: websiteApiService },
} = getConfig().services;

const API_CONFIG = {
  GET_ACCOUNT_ABOUT: (accountId: string) => `${websiteApiService}/account/about/${accountId}`,
  RESOLVE_SEO_URL: () => `${websiteApiService}/seo/resolve`,
  CONTACT_US: () => `${websiteApiService}/contact/us`,
  FILTER_PROJECT: () => `${websiteApiService}/content/project/filter`,
  FILTER_PROJECT_CATEGORY: () => `${websiteApiService}/content/project/category/filter`,
  PROJECT_BY_ID: (projectId: string) => `${websiteApiService}/content/project/${projectId}`,
  FILTER_BLOG: () => `${websiteApiService}/content/article/filter`,
  FILTER_BLOG_CATEGORY: () => `${websiteApiService}/content/article/category/filter`,
  BLOG_BY_ID: (projectId: string) => `${websiteApiService}/content/article/${projectId}`,
  FILTER_JOB: () => `${websiteApiService}/content/job/filter`,
  FILTER_JOB_CATEGORY: () => `${websiteApiService}/content/job/category/filter`,
  JOB_BY_ID: (projectId: string) => `${websiteApiService}/content/job/${projectId}`,
  FILTER_SERVICE: () => `${websiteApiService}/content/service/filter`,
  SERVICE_BY_ID: (serviceId: string) => `${websiteApiService}/content/service/${serviceId}`,
  PAGE_BY_ID: (pageId: string) => `${websiteApiService}/content/page/${pageId}`,
};

export default API_CONFIG;
