import {State} from '../../../types';

export const selectAppMetaData = (state: State) => {
  return state.app.metaData;
};

export const selectCurrentPageType = (state: State) => {
  return state.app.currentPageType;
};

export const selectCookies = (state: State) => {
  return state.app.cookies;
};

export const selectCoordinates = (state: State) => {
  return state.app.coordinates;
};
