import {css} from 'styled-components';

const positions = css`
  .positionRelative {
    position: relative !important;
  }

  .positionAbsolute {
    position: absolute !important;
  }
`;

export default positions;
