/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DO NOT EDIT. !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * autogenerated by tools/css/generateFlex.js
 */
import { sizes } from 'src/styles/mediaQueries';
import { css } from 'styled-components';

const flex = css`
  .flexRow {
    flex-direction: row !important;
  }

  .flexColumn {
    flex-direction: column !important;
  }

  .flexWrap {
    flex-wrap: wrap !important;
  }

  .flexNoWrap {
    flex-wrap: nowrap !important;
  }

  .flexSpaceBetween {
    justify-content: space-between !important;
  }

  .flexShrinkNone {
    flex-shrink: 0 !important;
  }

  @media (max-width: ${sizes.sDeviceUp}) {
    .mo-flexColumnReverse {
      flex-direction: column-reverse !important;
    }

    .mo-flexColumn {
      flex-direction: column !important;
    }
  }

  @media (min-width: ${sizes.sDeviceUp}) {
    .s-flexRow {
      flex-direction: row !important;
    }

    .s-flexColumn {
      flex-direction: column !important;
    }

    .s-flexWrap {
      flex-wrap: wrap !important;
    }

    .s-flexNoWrap {
      flex-wrap: nowrap !important;
    }

    .s-flexSpaceBetween {
      justify-content: space-between !important;
    }

    .s-flexShrinkNone {
      flex-shrink: 0 !important;
    }
  }

  @media (min-width: ${sizes.lDeviceUp}) {
    .xl-flexRow {
      flex-direction: row !important;
    }

    .xl-flexColumn {
      flex-direction: column !important;
    }

    .xl-flexWrap {
      flex-wrap: wrap !important;
    }

    .xl-flexNoWrap {
      flex-wrap: nowrap !important;
    }

    .xl-flexSpaceBetween {
      justify-content: space-between !important;
    }

    .xl-flexShrinkNone {
      flex-shrink: 0 !important;
    }
  }
`;

export default flex;
